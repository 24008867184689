
<script>
    import {addItemToCart} from './stores.js';

    export let productSku = '';
    export let productName = 'Human Maps';
    export let productDescription = 'All of the maps for the human campaign in full vibrant color';
    export let productPrice = 9.99;
    export let src = './images/Banded-Malachite-Polyhedral-Dice-Copper-Inlay.jpg';
    export let items = [];
    export let itemCount = 0;


    function addToCart() {
        console.log('Added to cart');
        itemCount += 1;
        console.log('item count = '+itemCount);
        addItemToCart({
            sku: productSku,
            name: productName,
            price: productPrice
        });
    }
</script>

<style>

    .product-card {
        border-radius: 20px;
        margin: 1em;
        height: 400px;
        width: 300px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
        border-radius: 5px;
        padding: 1em;     
        background-color: white;   
    }

    img {
        object-fit: contain;
        box-sizing: border-box;
        /* border-radius: 20px 20px 20px 20px; */
	    height: 200px;	
        width: 300px;
	}

    .card-title { 
        text-align: center;
    }

    .card-price p { 
        display: inline;
        margin-right: 9em;
    }
    

</style>

<div class="product-card">
    <img {src} alt="logo" >
    <div class="card-title">
        <h2>{productName}</h2>
    </div>
    <div class="card-text">
        <p>{productDescription}</p>
    </div>
    <div class="card-price">
        <p>${productPrice}</p>
        <button on:click="{addToCart}">Add to Cart</button>
    </div>

</div>

