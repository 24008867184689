<script>
    import NewsArticle from './NewsArticle.svelte';
    import { newsArticles } from './stores.js';
</script>

<style>
  h1 {
    text-align: center;
  }

  .page-header {
    text-align: center;
    background-color:darkslategray;
    color: white;
  }

</style>
<header class="page-header">
  <h1>News</h1>
</header>
{#each $newsArticles as article, i (article.id) }
<NewsArticle 
    id={article.id}
    date={article.date}
    owner={article.owner}
    likes={article.likes}
    tags={article.tags}
    title={article.title}
    description={article.description}
/>
{/each}