
<script>
	import Product from './Product.svelte';
	import { products } from './stores.js';
</script>

<style>
	.products-page {
			display: grid;
			 grid-template-columns: 1fr 1fr 1fr;
			 background-color: rgb(248, 248, 248);

		}

	header {
		/* background-color: #006666; */
		background-color: darkslategray;
		text-align: center;
		color: white;
		}
</style>


<header>
<h1>Products</h1>
</header>

<section class="products-page">
{#each $products as product, i (product.sku)}
	<Product  
	productSku={product.sku}
	productName={product.name}
	productDescription={product.description}
	productPrice={product.price}
	src={product.imageURL}
	/>
{/each}
</section>