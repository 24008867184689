<script>
  export let id = 0;
  export let date = '';
  export let owner = '';
  export let likes = 0;
  export let tags = [];
  export let title = '';
  export let description = '';
</script>

<style>
  article {
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    /* border: 1px solid black; */
    border-radius: 5px;
    width: auto;
    text-align: center;
    margin: 1rem;
    padding: 10px;
  }


  .article-header {
    text-align: left;
    background-color: #708685;
    border-radius: 5px;
    color: white;
    padding: 5px;
  }

  .content {
    text-align: left;
  }
</style>

<article>
  <header class="article-header">
    <div>
      <h2>{title}</h2>
    </div>
  </header>
  <div class="content">
    <p>{description}</p>
  </div>
  <footer>
  <div>
  <span>{date}</span>
  <span>{owner}</span>
  </div>
  <div>
  {#each tags as tag, i(tag)}
  <span>{tag}</span>
  {/each}
  </div>
  </footer>
</article>
