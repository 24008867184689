<script>
  import { appState } from "./stores.js";

  import TitleBar from "./TitleBar.svelte";
  import Home from "./Home.svelte";
  import NewsListing from "./NewsListing.svelte";
  import Login from "./Login.svelte";
</script>

<style>
  header {
    margin: 0;
    padding: 0;
    box-shadow: 0 2px 6xp rgbs(0, 0, 0, 0.26);
  }
</style>

<header>
  <TitleBar />
</header>

<div>
  {#if $appState === 'Home'}
    <Home />
  {:else if $appState === 'News'}
    <NewsListing />
  {:else if $appState === 'Login/Register'}
    <Login />
  {/if}
</div>
