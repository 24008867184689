import { writable } from 'svelte/store';
import { db } from './firebase';
import { auth } from './firebase';
import { authState } from 'rxfire/auth';
import { collectionData, doc } from 'rxfire/firestore';
import { startWith } from 'rxjs/operators';

// export const cart = writable(0);
export const appState = writable('Home');
let user;

const unsubscribe = authState(auth).subscribe(u => {
    user = u;
    grabCart();
});
// export const user = writable({firstName: 'Peter', lastname: 'Sotos'})

// Products
/* Sku system:
    d = digital
    p = poster
    m = miscellaneous
    t = t-shirt
*/
const queryProducts = db.collection('products').orderBy('sku');

export const products = collectionData(queryProducts, 'id').pipe(startWith([]));


// Articles
const queryArticles = db.collection('articles').orderBy('date');

export const newsArticles = collectionData(queryArticles, 'id').pipe(startWith([]));

// Cart
// let uid = "vMpc8T1FIpbQdVMm1AhionHRUu22";
let queryCart; 

export let cart;
let cartArray;
export let cartCount;

export function grabCart() {
    // console.log('Grab Cart');
    // console.log('user id',user.uid);
    queryCart = db.collection('carts').where('uid', '==', user.uid);

    collectionData(queryCart, 'id').pipe()
    .subscribe( val => {
        cartArray = val;
        // console.log('CartArray', cartArray);
        cart = db.doc('carts/'+cartArray[0].id);
        // cart = 
        console.log('Cart: ',cart);
//        cartCount = cart.collection('items').count();

        });


}

export function addItemToCart(item) {
    cart.collection('items').add(item);
}

/* export const products = writable([{
    id: 1,
    sku: 'sku-d-001',
    name: 'Human Maps',
    description: 'A PDF of all of the maps for the human campaign in full vibrant color.',
    imageURL: './images/products/Ambidia-2.jpg',
    price: 9.99
},
{
    id: 2,
    sku: 'sku-p-002',
    name: 'City of Ambidia',
    description: 'A full color wall poster of the city of Ambidia.',
    imageURL: './images/products/Ambidia-2.jpg',
    price: 35.00
},
{
    id: 3,
    sku: 'sku-m-003',
    name: 'AFRP Coffee Mug',
    description: 'A large 15oz ceramic mug to enjoy your coffee with.',
    imageURL: './images/products/coffee-mug.png',
    price: 15.00
},
{
    id: 4,
    sku: 'sku-d-004',
    name: 'Orc Maps',
    description: 'A full color wall poster of the far western Orc lands.',
    imageURL: './images/products/ClanOfTheDesertScorpionMap1.png',
    price: 35.00
},
{
    id: 5,
    sku: 'sku-t-005',
    name: 'Orc T-Shirt',
    description: 'A green t-shirt with an Orc Warrior and an Orc Mage.',
    imageURL: './images/products/orc-t-shirt.png',
    price: 25.00
},
{
    id: 6,
    sku: 'sku-t-006',
    name: 'Drow T-Shirt',
    description: 'A purple t-shirt with a Drow Warrior and a Drow Mage.',
    imageURL: './images/products/drow-t-shirt-w-logo.png',
    price: 25.00
},
{
    id: 7,
    sku: 'sku-t-007',
    name: 'Human T-Shirt',
    description: 'A white t-shirt with a color image of the Vampire Queen.',
    imageURL: './images/products/human-t-shirt.png',
    price: 35.00
}
]); */

/* export const newsArticles = writable([{
    id: 1,
    date: '2/25/2019',
    owner: 'psotos',
    likes: 0,
    tags: ['Press Release','Announcement', 'New Product'],
    title: 'AFRP launches new kickstarter campaign',
    description: 'Are you tired of playing good? Tired of the trope Paladin, cry baby? Want to slap him or her around a bit? Kick them good in the codpiece? Then this Zine is for you!',
},
{
    id: 2,
    date: '4/11/2019',
    owner: 'psotos',
    likes: 0,
    tags: ['Announcement'],
    title: 'AFRP Kickstarter successfully funds!',
    description: 'Amazing!',
}]); */
