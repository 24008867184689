<script>
    import Menu from './Menu.svelte';

    export let productName = 'Human Maps';
    export let productDescription = 'All of the maps for the human campaign in full vibrant color';
    export let productPrice = 9.99;
    export let src = './images/AFRP-logo2.svg';

</script>

<style>

	/* h1 {
		color: purple;
        font-family: becker;
	} */

    .title {
        background-color: darkslategrey;
        text-align: center;
          box-shadow: 0 2px 6xp rgbs(0, 0, 0, 0.26);        
        /* box-shadow: 5px 10px #888888; */
    }
    

</style>

<div class="title">
<Menu />
<img {src} alt="logo">
<!--  <h1>Advanced Fantasy Roleplaying, Inc.</h1> -->
</div>