import firebase from 'firebase/app';// rollup bundle issue with ESM import
import 'firebase/auth';
import 'firebase/firestore';
const firebaseConfig = {
  apiKey: "AIzaSyDgZTf2qcni5a0F9yXUj6Vdr8LzB4wNBA0",
  authDomain: "afrp-site-dfa34.firebaseapp.com",
  databaseURL: "https://afrp-site-dfa34.firebaseio.com",
  projectId: "afrp-site-dfa34",
  storageBucket: "afrp-site-dfa34.appspot.com",
  messagingSenderId: "225989490140",
  appId: "1:225989490140:web:2c509841a373f053"
};

console.log(firebase)

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();

export const db = firebase.firestore();