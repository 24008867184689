<script>
  import { appState, cart } from "./stores.js";
  
  function setActivePage(event) {
    let oldClassName = "";
    //Store the old class name
    //let oldClassName = event.target.className;
    // Remove a previous active
    //oldClassName = oldClassName.replace("active ","").trim();

    /* FIXME: The menus are not all behaving properly now that I have a shopping 
        cart added. I need to have sub menus and when the cart is clicked just 
        highlight my account. 
        I also need to toggle the menu between Login/Register and Log off. Lastly,
        I need to make Account invisible if the user is not logged in.
    */
    // Set all of the other menus to inactive
    var x = document.getElementsByTagName("LI");

    for (let i = 0; i < x.length; i = i + 1) {
      oldClassName = x[i].className;
      x[i].className = oldClassName.replace("active ", "").trim();
    }

    oldClassName = event.target.className;
    $appState = event.target.innerHTML ? event.target.innerHTML : "Cart";    
    event.target.className = "active " + oldClassName;
    console.log("AppState: " + $appState);
  }
</script>

<style>
  header {
    display: inline-flex;
  }
  .menu {
    margin: 0;
    display: inline-block;
    /* padding: 1em 1em 1em 2em; */
    /* height: 20px; */
  }

  i {
      cursor: pointer;
    color: white;
    width: 20px;
  }

  li {
    list-style-type: none;
    color: white;
    padding: 0 2em 0 2em;
    width: 80px;
    text-align: center;
  }

  .active {
    margin: 0;
    background-color: white;
    color: darkslategray;
  }

  li:hover {
      cursor: pointer;
    background-color: white;
    color: darkslategray;
  }

  nav {
    padding-top: 1em;
    background-color: darkslategray;
  }

  header {
    display: block;
  }

  .divider {
    display: inline-block;
    width: 30px;
  }

  .white {
      cursor: pointer;
    color: white;
  }

  .red {
      cursor: pointer;
         /* font-size:50%;
         background-color: white; */
       
        /* border-radius: 25px; */
        color: red;
  }
</style>

<header>
  <nav class="menu">
    <ul class="menu">
      <li class="active menu" on:click={setActivePage}>Home</li>
      <li class="menu" on:click={setActivePage}>News</li>
      <li class="menu" on:click={setActivePage}>Login/Register</li>
      <li class="menu" on:click={setActivePage}>Orders</li>
      <li class="menu" on:click={setActivePage}>Account</li>      
    </ul>
  </nav>

  <div class="menu">
    <!-- <i class="far fa-shopping-cart" /> -->
    <span class="fa-stack white">
      <!-- The icon that will wrap the number -->
      <span class="far fa-shopping-cart fa-stack-1x white"  on:click={setActivePage}/>
      <!-- a strong element with the custom content, in this case a number -->
      <strong class="fa-stack-1x red"></strong>
    </span>
    <span class="divider" />
    <a href="https://www.facebook.com/advancedfantasyroleplaying/" target="_blank"><i class="fab fa-facebook-f" /></a>
    <a href="https://twitter.com/AdvancedFRP" target="_blank"><i class="fab fa-twitter"></i></a>
  </div>
</header>
